@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    @apply font-space-gortesk scroll-smooth;
}

.hide_scroll::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    @apply !bg-light-gray w-1.5;
}

::-webkit-scrollbar-thumb {
    @apply bg-pacific-blue rounded-xl;
}

.swiper-wraper {
    transition-timing-function: linear;
}